import axios from 'axios';
import instance from './axios';
import toastService from './toast-service';

import { UNREACHABLE } from 'constants/vm-status';
import { AvailableMachineTemplateDTO, DcvResolution, PaginatedSessionsDTO, SessionPropertiesDTO, SiteStorageTemplates, VmDTO } from 'types';

const responseForbiddenMessage = ', please contact your site manager if you need to extend your access.';

export default {
  createVM(body: SessionPropertiesDTO): Promise<VmDTO> {
    return instance.post('/api/session/start', body).then(res => res.data);
  },

  deleteVM(sessionId: string) {
    this.cancelListRequests();
    return instance.delete('/api/session/' + sessionId)
      .then(res => res.data)
      .catch(() => {
        toastService.error('Error while deleting session');
        return Promise.reject();
      });
  },

  deleteVMSite(siteId: number) {
    this.cancelListRequests();
    return instance.delete('/api/session/delete-site/' + siteId)
      .then(res => {
        toastService.success('Signal sent to all sessions on site to be closed properly');
        return res.data;
      })
      .catch(() => {
        toastService.error('Error while stopping sessions on site');
        return Promise.reject();
      });
  },

  getVmURL(sessionId: string, dns) {
    const type = dns ? 'dns' : 'ip';
    const resolverURL = '/api/session/' + sessionId + '/url-' + type;
    return instance.get(resolverURL).catch((error) => {
      if (error.response.status === 403) {
        toastService.error('Error while while connecting to session' + responseForbiddenMessage);
      }
    });
  },

  pingVM(vmURL: string): Promise<number> {
    // The Same-Origin-Policy is triggering CORS preflight requests that are not handled by DCV server (dcv server does not accepts CORS)
    // As a result the request fails which is "normal"/"expected".
    // However in the case where the VM would be unreachable (dns error or network issues), we would also receive the VERY SAME error
    // => The javascript sandbox does not distinguish network errors ON-PURPOSE
    // As a result, we can't distinguish the CORS acceptable failure from a real error
    // Meaning that if we prevent the user from connecting to the vm if there is an error, then we won't be able to open dcv in devmode too

    // if (window.fetch) {
    // Keep-alive stopped to work, and therefore measured latency was way higher than actual (SSL negotiation)
    // So let's do an heuristic to estimate latency instead
    const now = new Date().getTime();
    return fetch(vmURL, { 'mode': 'no-cors' })
      .then(() => {
        const latency = (new Date().getTime() - now) / 4; // SSL tunnel establishment requires 3 request, plus the actual request that's 4 round trip
        return Math.round(latency);
      })
      .catch(() => UNREACHABLE);  // If this failed it means something is really wrong}
    // } else {
    //   return new Promise((resolve) => {
    //     // Cannot use this because of CORS:
    //     // instance.head(vmURL, config).then(res => new Date().getTime() - now).catch(() => UNREACHABLE);
    //     resolve(UNKNOWN);
    //   });
    // }
  },


  expandLifetime(sessionId, newExpirationDate) {
    return instance
      .patch('/api/session/' + sessionId + '/expand-lifetime', newExpirationDate)
      .then(res => {
        toastService.success('Session lifetime extended successfully');
        return res.data;
      })
      .catch((error) => {
        const baseMessage = 'Error expanding session lifetime';
        const message = error.response.status === 403 ? baseMessage + responseForbiddenMessage : baseMessage;
        toastService.error(message);
        return Promise.reject();
      });
  },

  updateVMResolution(sessionId: string, body: DcvResolution) {
    return instance
      .patch('/api/session/' + sessionId + '/resolution', body)
      .then(res => {
        toastService.success('Resolution updated successfully');
        return res.data;
      }).catch((error) => {
        const baseMessage = 'Error during update of resolution';
        const message = error.response.status === 403 ? baseMessage + responseForbiddenMessage : baseMessage;
        toastService.error(message);
        return Promise.reject();
      });
  },

  shareVM(sessionId: string, body): Promise<VmDTO> {
    return instance
      .patch('/api/session/' + sessionId + '/contributors', body)
      .then(result => {
        toastService.success('Shared users updated successfully');
        return result.data;
      }).catch((error) => {
        const baseMessage = 'Error while updating shared users';
        const message = error.response.status === 403 ? baseMessage + responseForbiddenMessage : baseMessage;
        toastService.error(message);
        return Promise.reject();
      });
  },

  /**
   * Cancel fetch of the vm list -- Must consider next one only.<br>
   * Can be useful when the state of a vm changes on client side and should not be overwritten by asynchronous calls.
   */
  cancelListRequests() {
    //TODO Find a way to cancel
    // if (this.source) {
    //   this.source.cancel();
    // }
  },

  getActiveVMInstances(pageNumber: number, pageSize: number): Promise<PaginatedSessionsDTO> {
    const source = axios.CancelToken.source();//FIXME Deprecated https://axios-http.com/docs/cancellation
    return instance.get(`api/session/list?page=${pageNumber}&size=${pageSize}`, {
      cancelToken: source.token,
    }).then(res => res.data);
  },

  getVNCAccessFileDownloadUrl(sessionId: string, dns) {
    const type = dns ? 'dns' : 'ip';
    return '/api/session/' + sessionId + '/download-' + type;
  },

  getMachineTemplates(siteId: number): Promise<AvailableMachineTemplateDTO[]> {
    return instance.get(`/api/resource/machine-templates/${siteId}`).
      then(res => {
        return res.data;
      })
      .catch((error) => {
        console.error('Error getting machine templates for site ' + siteId + ' : ' + error);
        return [];
      });
  },

  getStorageTemplates(siteId: number): Promise<SiteStorageTemplates> {
    return instance.get(`/api/resource/storage-templates/${siteId}`).then(res => {
      return { id: siteId, templates: res.data };
    });
  },


  updateSessionSmgInstall(sessionId: string, body) {
    return instance
      .patch('/api/session/' + sessionId + '/smginstalls', body)
      .then(() => {
        toastService.success('Installed Sismage versions updated successfully.');
      }).catch((error) => {
        const baseMessage = 'Error while updating installed Sismage versions';
        const message = error.response.status === 403 ? baseMessage + responseForbiddenMessage : baseMessage;
        toastService.error(message);
        return Promise.reject();
      });
  },


  sudoVM(sessionId: string) {
    return instance
      .patch('/api/session/' + sessionId + '/ask-sudo')
      .then(() => {
        toastService.success('Sudo granted');
      }).catch((error) => {
        const baseMessage = 'Error while granting sudo';
        const message = error.response.status === 403 ? baseMessage + responseForbiddenMessage : baseMessage;
        toastService.error(message);
        return Promise.reject();
      });
  },
};
