import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RootState } from 'store/reducers';
import { connect, ConnectedProps } from 'react-redux';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton } from '@mui/material';

import notificationService from 'services/notification-service';
import { POLLING_INTERVAL } from 'constants/config';

const mapState = (state: RootState) => ({
  isFetchingNotifications: state.notificationReducer.isFetching,
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

const Notification = ({ isFetchingNotifications }: ReduxProps) => {

  const [unreadNotifCount, setUnreadNotifCount] = useState<number>(0);

  useEffect(() => {
    const countAllUserUnreadNotifications = () => {
      if (isFetchingNotifications) {
        notificationService.countUnreadNotificationsByUser().then(count => {
          if (count || count === 0) {
            setUnreadNotifCount(count);
          }
        }).catch(err => {
          console.error(err);
        });
      }
    };

    //Call the service a first time
    countAllUserUnreadNotifications();
    //Then call the service again with a polling interval
    const interval = setInterval(() => {
      countAllUserUnreadNotifications();
    }, POLLING_INTERVAL);
    return () => { clearInterval(interval); };
  }, [isFetchingNotifications]);

  return (
    <div>
      <Link to='/manage-notifications'>
        <IconButton aria-label='comments'>
          <Badge data-testid='notification-badge' badgeContent={unreadNotifCount} color='error'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Link>
    </div>
  );
};

export default connector(Notification);
