import { BillingInfoDTO, BillingInfoLightDTO, SiteInfoDTO } from 'types';
import instance from './axios';
import toastService from './toast-service';

export default {
  getSiteInfo(id): Promise<SiteInfoDTO> {
    return instance.get(`api/resource/site-information/${id}`).then(res => res.data);
  },
  getSiteInfoExpired(id): Promise<SiteInfoDTO> {
    return instance.get(`api/resource/site-information-expired/${id}`).then(res => res.data);
  },
  patchSite(siteId, body): Promise<SiteInfoDTO> {
    return instance.patch(`api/resource/site/${siteId}`, body)
      .then(res => {
        toastService.success('Site updated successfully');
        return res.data;
      })
      .catch(err => {
        toastService.error(`Error while updating site ${err && err.response && err.response.data && err.response.data.technicalMessage ? `: ${err.response.data.technicalMessage}` : ''}`);
        return Promise.reject();
      });
  },
  getSiteCostInfoList(): Promise<BillingInfoDTO[]> {
    return instance.get('api/resource/billing-info').then(res => res.data);
  },
  getUserCostInfo(): Promise<BillingInfoLightDTO> { //FIXME why is this in site service ?
    return instance.get('api/resource/billing-info-user-light').then(res => res.data);
  },
};
