import ExpandLess from '@mui/icons-material/ArrowDropDown';
import ExpandMore from '@mui/icons-material/ArrowRight';
import AddUsersIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from '@mui/icons-material/Info';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import SessionIcon from '@mui/icons-material/OndemandVideo';
import UploadIcon from '@mui/icons-material/Publish';
import StorageIcon from '@mui/icons-material/Storage';
import { Collapse, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import classnames from 'classnames';
import AddFileUpload from 'components/file-upload/file-upload-button';
import CreateResource from 'components/resource/create-resource';
import CustomMenu from 'components/shared/menu/custom-menu';
import AddUsers from 'components/user/add-users';
import CustomRunVm from 'components/vm/vm-component/vm-custom-run';
import { MANAGER } from 'constants/user-authority';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import vmInstanceService from 'services/vm-instance-service';
import { addMachineTemplates } from 'store/actions/machine-template-actions';
import { initializeVMInstance } from 'store/actions/vm-instance-actions';
import { RootState } from 'store/reducers';
import { getSiteAvailableMachineTemplatesFromState } from 'store/selectors/machine-template-selectors';
import { ResourceAccessNode } from 'types';
import Zone from './menu-zone';

// TODO Split menu.module.scss
import { useAppDispatch } from 'store';
import styles from './sidebar.module.scss';

const mapState = (state: RootState) => ({
  getSiteAvailableMachineTemplatesFromState: (siteId) => getSiteAvailableMachineTemplatesFromState(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  site: ResourceAccessNode,
} & ReduxProps;

const Site = ({ site, getSiteAvailableMachineTemplatesFromState }: Props) => {
  const dispatch = useAppDispatch();
  const [manageMenuEl, setManageMenuEl] = useState(null);
  const [expandZoneList, setExpandZoneList] = useState(site.expanded ? site.expanded : false);

  useEffect(() => {
    setExpandZoneList(site.expanded ? site.expanded : false);
  }, [site.expanded]);

  const isSiteManager = site.authority === MANAGER;

  // TODO
  const isZoneExpiredWithAllSubProjects = false;

  const showManageActions = event => {
    setManageMenuEl(event.currentTarget);
    event.stopPropagation();
  };

  const hideManageActions = event => {
    if (event) {
      event.stopPropagation();
    }
    setManageMenuEl(null);
  };

  const manageItems = [
    {
      key: 'add-file-upload',
      display: site.isRunLevel,
      caption: (
        <AddFileUpload site={site} onClose={() => setManageMenuEl(null)}>
          <div className='justify-center' id='uploadMenuAction'>
            <UploadIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Upload file</span>
          </div>
        </AddFileUpload>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'file-download',
      display: site.isRunLevel,
      caption: (
        <Link to={`/download/site/${site.resourceId}`} className={styles.sidebar__icon_link + ' ' + styles.menu_item_content}>
          <div className='justify-center' id='downloadMenuAction'>
            <UploadIcon className={classnames(styles.sidebar__download_icon_reversed, styles.sidebar__action_menu_dropdown_icon)} />
            <span className={styles.sidebar__item_label}>Download</span>
          </div>
        </Link>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'custom-run',
      display: site.isRunLevel,
      caption: (
        <CustomRunVm site={site} onClose={() => setManageMenuEl(null)}>
          <div className='justify-center' id='customRunMenuAction'>
            <SessionIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Custom Run</span>
          </div>
        </CustomRunVm>
      ),
      onClick: hideManageActions,
    },
    {
      key: '1',
      display: isSiteManager,
      caption: (
        <AddUsers onClose={() => setManageMenuEl(null)} selectedResource={{ site }} withMenuStyle={true}>
          <div className='justify-center' id='addUserMenuAction'>
            <AddUsersIcon className={styles.sidebar__action_menu_dropdown_icon} />
            <span className={styles.sidebar__item_label}>Add users</span>
          </div>
        </AddUsers>
      ),
      onClick: hideManageActions,
    },
    {
      key: 'add-storage',
      display: isSiteManager,
      caption: (
        <CreateResource resourceType='Zone' selectedSiteId={site.resourceId} onClose={() => setManageMenuEl(null)}>
          <div className='justify-center'>
            <StorageIcon className={styles.action_menu_storage_icon} />
            <span className={styles.sidebar__item_label}>Add storage</span>
          </div>
        </CreateResource>
      ),
      onClick: hideManageActions,
    },
  ];

  const history = useHistory();

  const redirectToSessions = () => history.push('/');

  const preInitializeVMInstance = () => {
    const vmInstanceInit = {
      machineTemplateId: -1,
      resolution: {
        width: -1,
        height: -1,
      },
      managerMode: false,
      siteId: site.resourceId,
      resourcePath: site.resourcePath,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const availableMachineTemplates = getSiteAvailableMachineTemplatesFromState(site.resourceId);
    if (!availableMachineTemplates) {
      vmInstanceService.getMachineTemplates(site.resourceId).then((templates) => {
        const result = { [site.resourceId]: templates };
        dispatch(addMachineTemplates(result));
      });
    }
    dispatch(initializeVMInstance(vmInstanceInit));
    redirectToSessions();
  };

  const stopPropagation = event => {
    event.stopPropagation();
  };


  return (
    <>
      <ListItem className={classnames(styles.sidebar__list_item_container, styles.sidebar__list_item_container_site)}
        disableGutters
        button onClick={site.isExpired ? () => history.push(`/site-info/${site.resourceId}`) : () => setExpandZoneList(!expandZoneList)}
        disabled={isZoneExpiredWithAllSubProjects}
        data-testid='siteTestId'>
        <div className={styles.sidebar__list_item}>
          <div>
            {expandZoneList ? (<ExpandLess className={classnames(styles.sidebar__icon, { [styles.sidebar__icon_hidden]: site.isExpired })} />)
                : (<ExpandMore className={classnames(styles.sidebar__icon, { [styles.sidebar__icon_hidden]: site.isExpired })} />)}
            <ListItemIcon className={styles.sidebar__project_icon}>
              {site.nodeRepresentation &&
                <Tooltip title={site.nodeRepresentation || ''}>
                  <span className={classnames(styles.sidebar__country_flag, 'flag-icon', `flag-icon-${site.nodeRepresentation.toLowerCase()}`)} />
                </Tooltip>
              }
            </ListItemIcon>

            <ListItemText className={classnames(styles.sidebar__nav_item_text, styles.sidebar__overflow_ellipsis)}>
              <Typography sx={{ 'fontWeight': '500', 'lineHeight': '1.25' }}>
                {site.label}
              </Typography>
            </ListItemText>
          </div>

          <div>
            {site.isRunLevel && !site.isExpired && (
                <ListItemIcon className={styles.sidebar__action_icon} onClick={stopPropagation} id='runVMMenuAction'>
                  {
                    site.children != null && site.children.flatMap(z => z.children).length === 0 ?
                        (
                            <Tooltip title='No project available'>
                              <SessionIcon style={{ color: 'red' }} />
                            </Tooltip>
                        ) :
                        (<SessionIcon onClick={preInitializeVMInstance} />)
                  }
                </ListItemIcon>
            )}

            <ListItemIcon className={styles.sidebar__action_icon} id='infoMenuAction' onClick={stopPropagation}>
              <Link to={{ pathname: `/site-info/${site.resourceId}` }}>
                <InfoIcon className={`${site.isExpired ? styles.sidebar__action_icon_expired : styles.sidebar__action_icon}`} />
              </Link>
            </ListItemIcon>

            {!site.isExpired && (isSiteManager || site.isRunLevel) && (
              <>
                <ListItemIcon aria-controls='simple-menu' aria-haspopup='true' onClick={showManageActions} className={styles.sidebar__action_icon} id='actionIconMore'>
                  <MoreIcon />
                </ListItemIcon>
                <CustomMenu open={Boolean(manageMenuEl)} menuItems={manageItems} anchorElement={manageMenuEl} onClose={hideManageActions} />
              </>
            )}
          </div>
        </div>
      </ListItem>

      <Collapse in={expandZoneList}>
        <List className={styles.sidebar__zone_list}>
          {site.children
            .sort((z1, z2) => z1.name.localeCompare(z2.name))
            .map(zone => (
              <Zone key={zone.resourceId} site={site} zone={zone} />
            ))}
        </List>
      </Collapse>
    </>
  );
};

export default connector(Site);
