import { MANAGER } from 'constants/user-authority';
import siteService from 'services/site-service';
import userService from 'services/user-service';
import zoneService from 'services/zone-service';
import { AppThunk } from 'store/reducers';
import { BillingInfoDTO, ResourceAccessNode, SiteInfoDTO } from 'types';

/*
 * SITE INFO
 */
export const REQUEST_SITE_INFORMATION = 'REQUEST_SITE_INFORMATION';
export const RECEIVE_SITE_INFORMATION = 'RECEIVE_SITE_INFORMATION';
export const ERROR_FETCH_SITE_INFORMATION = 'ERROR_FETCH_SITE_INFORMATION';
export const UPDATE_SITE_INFO_SUCCESS = 'UPDATE_SITE_INFO_SUCCESS';

export const REQUEST_GET_SITE_COST_INFO_LIST = 'REQUEST_GET_SITE_COST_INFO_LIST';
export const RECEIVE_GET_SITE_COST_INFO_LIST = 'RECEIVE_GET_SITE_COST_INFO_LIST';
export const ERROR_GET_SITE_COST_INFO_LIST = 'ERROR_GET_SITE_COST_INFO_LIST';


export type RequestSiteCostInfoListAction = {
  type: typeof REQUEST_GET_SITE_COST_INFO_LIST
};

export type ReceiveSiteCostInfoListAction = {
  type: typeof RECEIVE_GET_SITE_COST_INFO_LIST,
  siteCostInfoList: BillingInfoDTO[]
};

export type ErrorSiteCostInfoListAction = {
  type: typeof ERROR_GET_SITE_COST_INFO_LIST
};

export type RequestSiteInfoAction = {
  type: typeof REQUEST_SITE_INFORMATION
};

export type ReceiveSiteInfoAction = {
  type: typeof RECEIVE_SITE_INFORMATION,
  siteInformation: SiteInfoDTO
};

export type ErrorFetchSiteInformationAction = {
  type: typeof ERROR_FETCH_SITE_INFORMATION
};

export type SiteInfoUpdate = {
  description: string,
  maxSessionPerUser: number,
  selectedSismageVersion: string,
};

export type UpdateSiteInfoSuccessAction = {
  type: typeof UPDATE_SITE_INFO_SUCCESS,
  siteId: number,
  siteInfo: SiteInfoUpdate,
};

export const requestSiteCostInfoListAction = (): RequestSiteCostInfoListAction => ({ type: REQUEST_GET_SITE_COST_INFO_LIST });
export const receiveSiteCostInfoListAction = (siteCostInfoList): ReceiveSiteCostInfoListAction => ({ type: RECEIVE_GET_SITE_COST_INFO_LIST, siteCostInfoList });
export const errorSiteCostInfoListAction = (): ErrorSiteCostInfoListAction => ({ type: ERROR_GET_SITE_COST_INFO_LIST });

export const requestSiteInfoAction = (): RequestSiteInfoAction => ({ type: REQUEST_SITE_INFORMATION });
export const receiveSiteInfoAction = (siteInformation: SiteInfoDTO): ReceiveSiteInfoAction => ({ type: RECEIVE_SITE_INFORMATION, siteInformation });
export const errorFetchSiteInformationAction = (): ErrorFetchSiteInformationAction => ({ type: ERROR_FETCH_SITE_INFORMATION });

export const updateSiteInfoSuccessAction = (siteId: number, siteInfo: SiteInfoUpdate): UpdateSiteInfoSuccessAction => ({ type: UPDATE_SITE_INFO_SUCCESS, siteId, siteInfo });

export const getSiteInfo = (siteId: number, expired: boolean): AppThunk<Promise<void | SiteInfoDTO>> => async dispatch => {
  dispatch(requestSiteInfoAction());

  const siteInfoService = expired ? siteService.getSiteInfoExpired : siteService.getSiteInfo;

  return siteInfoService(siteId)
      .then(siteInformation => {
        dispatch(receiveSiteInfoAction(siteInformation));
        return siteInformation;
      })
      .catch(() => {
        dispatch(errorFetchSiteInformationAction());
      });
};

export const updateSiteInfo = (siteId: number, description: string, maxSessionPerUser: number, selectedSismageVersion: string): AppThunk<Promise<void>> => async dispatch => {
  const body = {
    description: description,
    maxSessionPerUser: maxSessionPerUser,
    selectedSismageVersion: selectedSismageVersion,
  };
  return siteService.patchSite(siteId, body).then(() => {
    dispatch(updateSiteInfoSuccessAction(siteId, body));
  });
};

export const getSiteCostInfoList = (): AppThunk<Promise<void>> => async dispatch => {
  dispatch(requestSiteCostInfoListAction());

  siteService
      .getSiteCostInfoList()
      .then(siteCostInfoList => dispatch(receiveSiteCostInfoListAction(siteCostInfoList)))
      .catch(() => dispatch(errorSiteCostInfoListAction()));
};

/*
 * LIST SITES
 */
export const REQUEST_SITE_LIST = 'REQUEST_SITE_LIST';
export const RECEIVE_SITE_LIST = 'RECEIVE_SITE_LIST';
export const ERROR_FETCH_SITE_LIST = 'ERROR_FETCH_SITE_LIST';

export type RequestSiteListAction = {
  type: typeof REQUEST_SITE_LIST
};

export type ReceiveSiteListAction = {
  type: typeof RECEIVE_SITE_LIST,
  siteList: ResourceAccessNode[],
};

export type ErrorFetchSiteListAction = {
  type: typeof ERROR_FETCH_SITE_LIST
};

export const requestSiteListAction = (): RequestSiteListAction => ({ type: REQUEST_SITE_LIST });
export const receiveSiteListAction = (siteList: ResourceAccessNode[]): ReceiveSiteListAction => ({
  type: RECEIVE_SITE_LIST,
  siteList,
});
export const errorFetchSiteListAction = (): ErrorFetchSiteListAction => ({ type: ERROR_FETCH_SITE_LIST });

export const fetchSiteList = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const sitesState = getState().resourcesReducer;

  if (!sitesState.siteList.length) {
    dispatch(requestSiteListAction());
  }

  return userService
      .getUserAcl()
      .then(userAcl => {
        dispatch(receiveSiteListAction(userAcl.sites));
        return userAcl;
      })
      .then(userAcl => {
        const { zoneId2IsFileshareReady } = sitesState;
        const { zoneId2IsFileshareFailed } = sitesState;

        userAcl.sites.forEach(site => site.children.forEach(
            zone => zone.authority === MANAGER && !zoneId2IsFileshareReady[zone.resourceId] && zoneService.getZoneFileshareStatusWithPolling(site.resourceId, zone.resourceId, dispatch),
            zone => zone.authority === MANAGER && !zoneId2IsFileshareFailed[zone.resourceId] && zoneService.getZoneFileshareStatusWithPolling(site.resourceId, zone.resourceId, dispatch)
        ));

      })
      .catch(() => {
        dispatch(errorFetchSiteListAction());
      });
};

export type SiteActions = RequestSiteCostInfoListAction
    | ReceiveSiteCostInfoListAction
    | ErrorSiteCostInfoListAction
    | RequestSiteInfoAction
    | ReceiveSiteInfoAction
    | ErrorFetchSiteInformationAction
    | UpdateSiteInfoSuccessAction
    | RequestSiteListAction
    | ReceiveSiteListAction
    | ErrorFetchSiteListAction;